<script setup lang="ts">
import { autoPlacement, autoUpdate, flip, inline, offset, shift, useFloating } from '@floating-ui/vue'

const reference = ref(null)
const floating = ref(null)
const open = ref(false)

const { floatingStyles } = useFloating(reference, floating, {
  open,
  whileElementsMounted: autoUpdate,
  // strategy: 'fixed',
  placement: 'top',
  middleware: [autoPlacement(), flip(), shift(), offset(10), inline()],
})
</script>

<template>
  <button ref="reference" @mouseover="open = true" @mouseleave="open = false">
    <slot name="toggle" />
  </button>
  <div v-if="open" ref="floating" :style="floatingStyles" class="z1">
    <div class="max-w-sm rd-lg bg-black/70 px4 py2 text-white shadow backdrop-blur">
      <slot />
    </div>
  </div>
</template>
